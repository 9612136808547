;(function($) {
  var $element = $('.js-toggle-class');
  var $class = $element.data('class');
  var $preventDefault = $element.data('prevent-default');
  $element.on('click', function(e) {
    if ($preventDefault) {
      e.preventDefault();
    }
    $(this).toggleClass($class);
  })
})(jQuery);