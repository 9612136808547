(function ($) {
  var $sliderContainer = $('.js-range-slider-wrap');



  $sliderContainer.each(function () {
    var $sliderInput,
        $sliderMinInput,
        $sliderMaxInput,
        rangeSliderConfig;
    $sliderInput = $(this).find('.js-range-slider');
    $sliderMinInput = $(this).find('.js-min-input');
    $sliderMaxInput = $(this).find('.js-max-input');

    rangeSliderConfig = {
      onStart: function (data) {
        $sliderMinInput.val(data.from);
        $sliderMaxInput.val(data.to);
      },
      onChange: function (data) {
        $sliderMinInput.val(data.from);
        $sliderMaxInput.val(data.to);
      },
      onFinish: function (data) {
        $sliderMinInput.val(data.from);
        $sliderMaxInput.val(data.to);
      },
      onUpdate: function (data) {
        $sliderMinInput.val(data.from);
        $sliderMaxInput.val(data.to);
      }
    };
    $sliderInput.ionRangeSlider(rangeSliderConfig);
  });
})(jQuery);