$(document).ready(function () {
  var $pinContainer = $('.pin');
  if($pinContainer.length) {
    $pinContainer.easypinShow({
      data: easypinObject,
      responsive: true,
      popover: {
        show: false,
        animate: true
      },
      each: function (index, data) {
        return data;
      },
      error: function (e) {
        console.log(e);
      },
      success: function () {
        var $pin = $('.easypin-marker');
        $pin.on('click', function() {
          var $curThis = $(this);
          $('.easypin-marker').not($curThis).removeClass('is-active');
          if(!$curThis.hasClass('is-active')) {
            $curThis.addClass('is-active');
          } else {
            $curThis.removeClass('is-active');
          }
        });
        $pin.each(function() {
          var $photo = $(this).find('.js-team-photo'),
              $photoSrc = $photo.data('src');
          $photo.attr('src', $photoSrc);
          var $markerText = $(this).find('.team-member').data('markertext');
          $(this).find('.easypin-marker__text').text($markerText);
        });
      }
    });
  }
});
