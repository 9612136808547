;(function ($) {
  var $triggerBtn = $('.js-object-preview'),
    sidebarContainer = document.getElementById('sidebar-container'),
    $objId,
    sidebarContentUrl;


  //отображение номера офиса в форме и скрытом поле для отправки
  var displayOfficeNum = function () {
    var $officeNumberInput = $('.js-office-num'),
      $showFormBtn = $('.js-show-form'),
      $officeNumber,
      $officeNumContainer = $('.js-office-number-container');

    $showFormBtn.on('click', function () {
      $officeNumber = $(this).data('office-number');
      $officeNumberInput.val($officeNumber);
      $officeNumContainer.text('№' + $officeNumber);
    });
  };

  //Выбор активного таба

  var activeTab = function() {
    var $tabPreselectBtn = $('.js-tab-preselect'),
        $tabs = $('#form-tabs'),
        $panelId;

    $tabPreselectBtn.on('click', function() {
      $panelId = $(this).data('tab-panel');
      $tabs.foundation("selectTab",$panelId);
    });
  };

  $triggerBtn.on('click', function () {
    $objId = $(this).data('object-preview');
    sidebarContentUrl = '/upage//' + $objId + '?transform=partials/catalog/catalog-lhs-preview.xsl';
    $(sidebarContainer).hide().load(sidebarContentUrl, function() {
      displayOfficeNum();
      activeTab();
    }).hide().fadeIn(800);
  });
  displayOfficeNum();
  activeTab();
})(jQuery);