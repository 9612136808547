;(function ($) {
  var $navBtn = $('.js-show-nav');
  var $nav = $('.navigation');
  var $navClose = $('.js-close-nav');
  var $visibleBlock = $('.nav-visible');
  var $navWrap = $('.nav-overflow');
  var $subMenuBtn = $('.js-nav-drop-trigger');
  var $submenu = $('.nav-submenu');
  /*
   при клике на лого, если меню скрыто:
   1. Скрываем логотип или дочернее меню
   2. Удаляем скрывающий меню класс и добавляем анимацию.
   После проигрывания анимации:
   1. Удаляем анимацию у меню.
  */

  /*
   при клике на крестик:
   1. Добавляем анимацию
   После проигрывания анимации:
   1. Добавляем скрывающий класс меню
   2. Удаляем анимацию у меню.
   3. Удаляем скрывающий класс у лого/ дочернего меню.
   */
  $navBtn.on('click', function (e) {
    e.preventDefault();
    if ($nav.hasClass('is-hidden')) {
      $visibleBlock.addClass('is-hidden');
      $navWrap.css('overflow', 'hidden');
      $nav.removeClass('is-hidden').addClass('fadeInLeft animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
        $(this).removeClass('fadeInLeft animated');
        $navWrap.css('overflow', 'visible');
      });
    }
  });
  $navClose.on('click', function () {
    $navWrap.css('overflow', 'hidden');
    $nav.addClass('fadeOutLeft animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
      $(this).addClass('is-hidden').removeClass('fadeOutLeft animated');
      $visibleBlock.removeClass('is-hidden');
      $navWrap.css('overflow', 'visible');
    });
  });

  $subMenuBtn.on('click', function (e) {
    e.preventDefault();
    $submenu.toggleClass('is-active');
  })

})(jQuery);