;(function($) {
  var $slider = $('.js-flexslider-pure');
  if($slider.length) {
    $slider.flexslider({
      controlNav: false
    });
  }

  var $office = $('.js-office-maxheight');
  var pageTopOffset = 25;
  var officeTopOffset = 65;
  var bottomSpace = 70;
  var $officeTopbarHeight = $('.page-title').height() + $('.office__header').height() + pageTopOffset + officeTopOffset + bottomSpace;
  var $windowHeight = $(window).height();
  $office.css('max-height', $windowHeight - $officeTopbarHeight);
})(jQuery);