(function ($) {

  //================ contacts overlay + map height
  var mapsContainer = $('.js-google-maps');

  //===================== map

  function initMap() {
    var coordinate = mapsContainer.data('coordinates').replace(/\s+/g, '').split(',');
    var markerLongitude = parseFloat(coordinate[1]) + 0.00035;
    console.log(markerLongitude);
    var latLang = new google.maps.LatLng(coordinate[0], coordinate[1]);
    var markerLatLang = new google.maps.LatLng(coordinate[0], markerLongitude);
    var mapOptions = {
      center: latLang,
      zoom: 17,
      streetViewControl: false,
      scrollwheel: true,
      draggable: true,
      mapTypeControl: false,
      scaleControl: true
    };

    var map = new google.maps.Map(document.getElementById('js-google-maps'), mapOptions);

    var image = 'assets/img/map-marker.png';
    var marker = new google.maps.Marker({
      position: markerLatLang,
      map: map,
      draggable: false,
      icon: image
    });
  }
  if(mapsContainer.length) {
    initMap();
  }

})(jQuery);