;(function($) {

  $(window).on('load resize', function () {
    var PAGE_TOP_OFFSET = 25,
        HEADER_OFFSET = 50,
        PAGE_BOTTOM_OFFSET = 25,
        $headerHeight = $('.js-header-wrap').outerHeight(),
        $heightContainer = $('.js-calc-height'),
        $windowHeight = $(window).height(),
        totalHeight;

    totalHeight = $windowHeight - ($headerHeight + PAGE_TOP_OFFSET + HEADER_OFFSET + PAGE_BOTTOM_OFFSET);

    $heightContainer.css('max-height', totalHeight);
  });



})(jQuery);