(function ($) {
    var $customScrollLight = $('.js-custom-scroller'),
        $customScrollDark = $('.js-custom-scroller-dark');
    if($customScrollLight.length) {
      $customScrollLight.mCustomScrollbar({
        theme: 'light-thin',
        scrollButtons: {
          enable: true
        }
      });
    }
    if($customScrollDark.length) {
      $customScrollDark.mCustomScrollbar({
        theme: 'dark-thin',
        scrollButtons: {
          enable: true
        }
      });
    }
})(jQuery);